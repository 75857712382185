import { template as template_b4f5047415d146b5aab0b09dbbf5491e } from "@ember/template-compiler";
export default template_b4f5047415d146b5aab0b09dbbf5491e(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
