import { template as template_c688db7a97c2424397752a4ff0b34aac } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_c688db7a97c2424397752a4ff0b34aac(`
  <div class="element-text">
    {{htmlUnsafe @text.content}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
