import { template as template_d5210a63b5c84df28d30c72f1ccdca10 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import FooterLinks from './footer-links';
export default class Footer extends Component {
    @service
    currentDomain;
    get currentYear() {
        const date = new Date();
        return date.getFullYear().toString();
    }
    static{
        template_d5210a63b5c84df28d30c72f1ccdca10(`
    <footer id="footer" class="footer" role="contentinfo">
      <div class="footer__logos">
        {{#if this.currentDomain.isFranceDomain}}
          <img
            src="/images/logo/logo-de-la-republique-francaise.svg"
            class="footer__logos--french-republic-logo"
            alt="{{t 'common.french-republic'}}"
          />
        {{/if}}
        <img src="/images/pix-logo.svg" class="footer__logos--pix-logo" alt={{t "common.pix"}} />
        <div class="copyrights">
          <span>{{t "navigation.copyrights"}} {{this.currentYear}} {{t "navigation.pix"}}</span>
        </div>
      </div>
      <FooterLinks @textAlign="right" />
    </footer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
