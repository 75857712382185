import { template as template_bbe62d3cd2764d5ba2c02fa7ead7147f } from "@ember/template-compiler";
import PixBackgroundHeader from '@1024pix/pix-ui/components/pix-background-header';
import PixBlock from '@1024pix/pix-ui/components/pix-block';
import CertificationStarter from '../certification-starter';
import CompanionBlocker from '../companion/blocker';
export default template_bbe62d3cd2764d5ba2c02fa7ead7147f(`
  <CompanionBlocker>
    <main class="main" role="main">
      <PixBackgroundHeader id="main">
        <PixBlock @shadow="heavy" class="certification-start-page__block">
          <CertificationStarter @certificationCandidateSubscription={{@certificationCandidateSubscription}} />
        </PixBlock>
      </PixBackgroundHeader>
    </main>
  </CompanionBlocker>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
